export function useAction() {
  const isPerformingAction = ref(false);

  function startAction() {
    isPerformingAction.value = true;
  }

  function endAction() {
    isPerformingAction.value = false;
  }

  return {
    isPerformingAction,
    startAction,
    endAction,
  };
}

export function useAction2<T, Fn extends (...args: any[]) => Promise<T>>(
  fn: Fn,
) {
  const pending = ref(false);

  const wrappedFunction = async (...args: any[]) => {
    pending.value = true;

    try {
      const res = await fn(...args);
      pending.value = false;
      return res;
    } catch (error) {
      pending.value = false;
      throw error;
    }
  };

  return {
    pending,
    action: wrappedFunction as Fn,
  };
}
